import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot ,Router} from '@angular/router';
import { AuthService } from 'src/app/services/authorization-details/auth-and-user-details.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login-service/login.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  currentLang = window.localStorage.getItem('lang') || 'en'
  constructor(private authService: AuthService, private router: Router,private loginService:LoginService) { }

  // Role specific guard for authorization purpose.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let userInfo = this.authService.getUserInfo()
    if (!userInfo) {
     // window.location.href = `${environment.loginPageUrl}/login`;
      window.location.href = `${window.location.origin}/${this.currentLang}/login`;
      return false;
    }else{
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(userInfo.roleName.trim()) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate([`/${this.currentLang}/unauthorized`]);
        return false;
    }

    // authorised so return true
        return true

    } 
  }
 
}

