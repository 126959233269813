import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-assessment-info",
  templateUrl: "./assessment-info.component.html",
  styleUrls: ["./assessment-info.component.scss"],
})
export class AssessmentInfoComponent implements  OnChanges {
  @Input() info:any = {};
  @Input() cardType = 'assessment';  //'assessment or planning_process'
  ngOnChanges(changes: SimpleChanges) {
    if (changes.info && changes.info.currentValue) {
      this.info = changes.info.currentValue;
    }
  }
}
