import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Tokens } from '../../models/Tokens';
import { DOCUMENT } from '@angular/common';
import { StorageService } from '../storage.service';
import { User } from '../../models/User';
import { environment } from 'src/environments/environment';
import {USER_ROLES} from 'src/app/constants/Constants'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenSubject = new BehaviorSubject<Tokens | null>(null);
  currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  currentUserRoleSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  currentSupplierIdSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) public document: any, private storageService: StorageService) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUserRoleSubject = new BehaviorSubject<User | null>(null);
    this.currentSupplierIdSubject = new BehaviorSubject<User | null>(null);

  }
  setInternalUserLogin(checked: string) {
    this.storageService.setInStorage('isInternalUserLogin', checked);
    let authURL = environment.authentication_url;
    this.document.location.href = authURL;
  }
  setExternalUserLogin(checked: string) {
    this.storageService.setInStorage('isExternalUserLogin', checked);
    let authURL = environment.authentication_url;
    this.document.location.href = authURL;
  }


  setTokens(tokens: Tokens | null) {
    this.tokenSubject.next(tokens);
  }

  getToken() {
    return this.storageService.getFromStorage('token');
  }

   isLoggedIn(){
     return !!this.storageService.getFromStorage('isLoggedIn');
   }
  setAdmin(admin: string) {
    this.storageService.setInStorage('adminChecked', admin);
  }
  public getUserInfo() {
    return this.storageService.getObjectFromStorage('userInfo')
  }
  public getCurrentUser(){
    let userInfo = this.getUserInfo()
    return `${userInfo?.firstName} ${userInfo?.lastName} (${userInfo?.userNickName})`;
  }

  public getLoggedInUserID(){
    let userInfo = this.getUserInfo()
    return userInfo?.userNickName;
  }
  public extractUserID(userName){
    //userName = 'firstName LastName (uniqueID)'
    if(!userName) return null
    return userName.slice(userName.indexOf('(')+1,userName.indexOf(')'))
  }
  public isRegionalSuperUser(regionName){
    //super users with same region as of assessment
      let userInfo= this.getUserInfo()
      let role = userInfo.roleName.trim()
      return role == USER_ROLES.SUPER_USERS && userInfo.userRegion.includes(regionName)

 }
}
