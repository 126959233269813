import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    _sk = 'xz345&8'

    constructor() {
        // This is intentional
    }

    setObjectInStorage(key: string, value: object) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getObjectFromStorage(key: string) {
        const obj = localStorage.getItem(key);
        if (obj && obj != null) {
            return JSON.parse(obj);
        }
        return null;
    }

    setInStorage(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    getFromStorage(key) {
        return localStorage.getItem(key);
    }

    removeFromStorage(key) {
        localStorage.removeItem(key);
    }
    

    /*
    setObjectInStorage(key: string, value: object) {
        //let _enKey = this.encrypt(key) 
        localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
    }

    getObjectFromStorage(key: string) {
        //let _enKey = this.encrypt(key)
        const obj = localStorage.getItem(key);
    
        if (obj && obj != null) {

            return JSON.parse(this.decrypt(obj));
        }
        return null;
    }

    setInStorage(key: string, value: any) {
        
        //let _enKey = this.encrypt(key) 
        localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
    }

    getFromStorage(key) {
        //let _enKey = this.encrypt(key)
        let enVal = localStorage.getItem(key)
        if(enVal && enVal != null){
            return this.decrypt(enVal);
        }else{
            return null
        }
    }

    removeFromStorage(key) {
        //let _enKey = this.encrypt(key) 
        localStorage.removeItem(key);
    }*/
    clearFullStorage() {
        localStorage.clear();
    }


    private encrypt(txt: string): string {
        return CryptoJS.AES.encrypt(txt, this._sk).toString();
      }
      
      private decrypt(txtToDecrypt: string) {
        return CryptoJS.AES.decrypt(txtToDecrypt, this._sk).toString(CryptoJS.enc.Utf8);
      }
}
