import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment} from 'src/environments/environment';
import { LoginService } from 'src/app/services/login-service/login.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'login-welcome',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent  {
  email: string = '';
  showWelcome = true;
  color = "secondary"
  showLoadingBtn = false
  rrmRegisterURL=environment.rrmRegisterURL
  iamRegisterURL=environment.iamRegisterURL
  loginMethod='internal'
  currentLang = window.localStorage.getItem('lang') || 'en'
  constructor(private loginService: LoginService, private router: Router,private commonService:CommonService) {
    const url = window.location.href;
    const codeIdx = url.indexOf('code=');
    if (codeIdx !== -1) {
    
      this.showWelcome = false;
      let idp = window.localStorage.getItem('loginProvider')
      this.showLoadingBtn = true
      this.loginMethod=idp
      // window.localStorage.removeItem('loginProvider')
      // window.sessionStorage.removeItem('loginProvider')
      const fragment = url.substr(codeIdx);
      const fragmentsMap = this.extractKeyValueBasedOnDelimiter(fragment);
    
      const code = fragmentsMap['code'];
      this.loginService.fetchTokensForCode(code).subscribe({
        next: (tokens) => {
          this.loginService.authorize(tokens);
        },
        error: (err) => {
          this.router.navigate([`${this.currentLang}/login`]);
        }
      });
    } 
    else {
      this.showWelcome = true;
      this.showLoadingBtn = false
    
    }
  }

  segmentChanged(event){
    this.loginMethod = event.target.value
  }
  login(){
    if( this.loginMethod == 'internal'){
     this.internalUserLogin()
    }else if( this.loginMethod=='external'){
       this.externalUserLogin()
    }
  }
  internalUserLogin() {
     /***
     * 
     * Login through AzureAD (with wwid)
     * **/
    this.showLoadingBtn = true
  
    window.sessionStorage.setItem('loginProvider','internal')
    window.localStorage.setItem('loginProvider','internal')
    let loginUrl = environment.authentication_url+environment.azureProvider
   
  
    // let langSpecificUrl = loginUrl.replace(`&redirect_uri=${environment.oauth2.redirectUri}&`,`&redirect_uri=${window.location.origin}&`)
    let langSpecificUrl = this.commonService.getLangugeSpecificURL(loginUrl)
    
    window.location.href=langSpecificUrl
  }
  externalUserLogin() {
    /***
     * 
     * Login through Salesforce
     * **/
    this.showLoadingBtn = true
  
    window.sessionStorage.setItem('loginProvider','external')
    window.localStorage.setItem('loginProvider','external')
    let loginUrl = environment.authentication_url+environment.salesForceProvider
    // let langSpecificUrl = loginUrl.replace(`&redirect_uri=${environment.oauth2.redirectUri}&`,`&redirect_uri=${window.location.origin}&`)
    let langSpecificUrl = this.commonService.getLangugeSpecificURL(loginUrl)
    
    window.location.href=langSpecificUrl
  
  }
  extractKeyValueBasedOnDelimiter = (str, delimiter = '&') => {
    const map = {};
    if (str && str != null) {
      const fragArray = str.split(delimiter);
      for (const frag of fragArray) {
        const arr = frag.split('=');
        map[arr[0]] = arr[1];
      }
    }
    return map;
  }

}