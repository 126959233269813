import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ProfileComponent } from "./components/profile/profile.component";
import { USER_ROLES } from './constants/Constants';
import { AuthGuard } from './services/auth-guard/auth.guard';
import { LoginGuard } from './services/auth-guard/login.guard';
let currentLang = 'en';

(function(){

  let langCode = ['zhcn','ptbr','frca','esla','en']
  let pathName = window.location.pathname
   
    let langFromUrl= null
    for(let l of langCode){
      if(pathName.startsWith(`/${l}/`)){
        langFromUrl = l
        break
      }
    }
    if(langFromUrl){
      window.localStorage.setItem('lang',langFromUrl)
      currentLang = langFromUrl
    }else{
      currentLang = window.localStorage.getItem('lang') || 'en'
    }
  
})()

//currentLang = window.localStorage.getItem('lang') || 'en'
const routes: Routes = [
  {
    path: '',
    redirectTo: `${currentLang}/login`,
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: `${currentLang}/login`,
    pathMatch: 'full'
  },
  {
    path: `:${currentLang}/login`,
    component: LoginComponent,
    canActivate:[LoginGuard]
  },
  {
    path: 'dashboard',
    redirectTo: `${currentLang}/dashboard`,
    pathMatch: 'full'
  },
  {
    path: 'tracker',
    redirectTo: `${currentLang}/tracker`,
    pathMatch: 'prefix'
  },
  {
    path: ':langCode',
    children:[
          
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
        canActivate:[AuthGuard]
      },
      {
        path: 'top-level-assessment/:assessmentId',
        canActivate:[AuthGuard],
        loadChildren: () => import('./pages/top-level-assessment/top-level-assessment.module').then( m => m.TopLevelAssessmentPageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
        }

      },
      {
        path: 'master-data-configuration',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.CONTENT_OWNERS]
        },
        loadChildren: () => import('./pages/master-data-configuration/master-data-configuration.module').then( m => m.MasterDataConfigurationPageModule)
      },
      {
        path: 'user-access-management',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER]
        },
        loadChildren: () => import('./pages/user-access-management/user-access-management.module').then( m => m.UserAccessManagementPageModule)
      },
      {
        canActivate:[AuthGuard],
        path: 'final-outcome/:assessmentId',
        loadChildren: () => import('./pages/final-outcome/final-outcome.module').then( m => m.FinalOutcomePageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
        }
      },
      {
        canActivate:[AuthGuard],
        path: 'set-priority/:assessmentId',
        loadChildren: () => import('./pages/set-priority/set-priority.module').then( m => m.SetPriorityPageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS, USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS,USER_ROLES.USERS]
        }
      },
      {
        canActivate:[AuthGuard],
        path: 'action-plan',
        loadChildren: () => import('./pages/action-plan/action-plan.module').then( m => m.ActionPlanPageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
        }
      },
      
      {
        path: 'competency-requirements-configuration',
        canActivate:[AuthGuard],
        loadChildren: () => import('./pages/competency-requirements-configuration/competency-requirements-configuration.module').then( m => m.CompetencyRequirementsConfigurationPageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.CONTENT_OWNERS]
        }
      },
      {
        path: 'assessment',
        canActivate:[AuthGuard],
        loadChildren: () => import('./pages/new-assessment/new-assessment.module').then( m => m.NewAssessmentPageModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.CONTENT_OWNERS]
        }
        
      },
      {
        path: 'self-assessment/:assessmentId',
        canActivate:[AuthGuard],
        loadChildren: () => import('./pages/self-swap-assessment/self-swap-assessment.module').then( m => m.SelfSwapAssessmentModule),
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
        }
      },
      {
        path: 'swap-assessment/:assessmentId',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
        },
        loadChildren: () => import('./pages/self-swap-assessment/self-swap-assessment.module').then( m => m.SelfSwapAssessmentModule)
      },
      {
        path: 'tracker',
        canActivate:[AuthGuard],
        loadChildren: () => import('./pages/tracker/tracker.module').then( m => m.TrackerPageModule)
      },
      {
        path: 'planning-process/dashboard',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS]
        },

        loadChildren: () => import('./pages/planning-process-dashboard/planning-process-dashboard.module').then( m => m.PlanningProcessDashboardPageModule)
      },
      {
        path: 'planning-process/create',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER]
        },
        loadChildren: () => import('./pages/new-planning-process/new-planning-process.module').then( m => m.NewPlanningProcessPageModule)
      },
      {
        path: 'planning-process/update/:ppID',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER]
        },
        loadChildren: () => import('./pages/new-planning-process/new-planning-process.module').then( m => m.NewPlanningProcessPageModule)
      },
      {
        path: 'planning-process/stakeholder-assignment/:ppID',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS]
        },
        loadChildren: () => import('./pages/planning-process-stakeholder-assignment/planning-process-stakeholder-assignment.module').then( m => m.PlanningProcessStakeholderAssignmentPageModule)
      },
      {
        path: 'planning-process/data-collection/:ppID',
        canActivate:[AuthGuard],
        data:{
          roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS]
        },
        loadChildren: () => import('./pages/planning-process-training-status/planning-process-training-status.module').then( m => m.PlanningProcessTrainingStatusPageModule)
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'profile',
        canActivate:[AuthGuard],
        component: ProfileComponent
      }
    ]

  },

  // {
  //   path: ':langCode/dashboard',
  //   loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
  //   canActivate:[AuthGuard]
  // },
  // {
  //   path: ':langCode/top-level-assessment/:assessmentId',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./pages/top-level-assessment/top-level-assessment.module').then( m => m.TopLevelAssessmentPageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
  //   }

  // },
  // {
  //   path: ':langCode/master-data-configuration',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.CONTENT_OWNERS]
  //   },
  //   loadChildren: () => import('./pages/master-data-configuration/master-data-configuration.module').then( m => m.MasterDataConfigurationPageModule)
  // },
  // {
  //   path: ':langCode/user-access-management',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER]
  //   },
  //   loadChildren: () => import('./pages/user-access-management/user-access-management.module').then( m => m.UserAccessManagementPageModule)
  // },
  // {
  //   canActivate:[AuthGuard],
  //   path: ':langCode/final-outcome/:assessmentId',
  //   loadChildren: () => import('./pages/final-outcome/final-outcome.module').then( m => m.FinalOutcomePageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
  //   }
  // },
  // {
  //   canActivate:[AuthGuard],
  //   path: ':langCode/set-priority/:assessmentId',
  //   loadChildren: () => import('./pages/set-priority/set-priority.module').then( m => m.SetPriorityPageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS, USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS,USER_ROLES.USERS]
  //   }
  // },
  // {
  //   canActivate:[AuthGuard],
  //   path: ':langCode/action-plan',
  //   loadChildren: () => import('./pages/action-plan/action-plan.module').then( m => m.ActionPlanPageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
  //   }
  // },
  
  // {
  //   path: ':langCode/competency-requirements-configuration',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./pages/competency-requirements-configuration/competency-requirements-configuration.module').then( m => m.CompetencyRequirementsConfigurationPageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.CONTENT_OWNERS]
  //   }
  // },
  // {
  //   path: ':langCode/assessment',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./pages/new-assessment/new-assessment.module').then( m => m.NewAssessmentPageModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.CONTENT_OWNERS]
  //   }
    
  // },
  // {
  //   path: ':langCode/self-assessment/:assessmentId',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./pages/self-swap-assessment/self-swap-assessment.module').then( m => m.SelfSwapAssessmentModule),
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
  //   }
  // },
  // {
  //   path: ':langCode/swap-assessment/:assessmentId',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS,USER_ROLES.CONTENT_OWNERS,USER_ROLES.VIEWERS]
  //   },
  //   loadChildren: () => import('./pages/self-swap-assessment/self-swap-assessment.module').then( m => m.SelfSwapAssessmentModule)
  // },
  // {
  //   path: ':langCode/tracker',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('./pages/tracker/tracker.module').then( m => m.TrackerPageModule)
  // },
  // {
  //   path: ':langCode/planning-process/dashboard',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS]
  //   },

  //   loadChildren: () => import('./pages/planning-process-dashboard/planning-process-dashboard.module').then( m => m.PlanningProcessDashboardPageModule)
  // },
  // {
  //   path: ':langCode/planning-process/create',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER]
  //   },
  //   loadChildren: () => import('./pages/new-planning-process/new-planning-process.module').then( m => m.NewPlanningProcessPageModule)
  // },
  // {
  //   path: ':langCode/planning-process/update/:ppID',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER]
  //   },
  //   loadChildren: () => import('./pages/new-planning-process/new-planning-process.module').then( m => m.NewPlanningProcessPageModule)
  // },
  // {
  //   path: ':langCode/planning-process/stakeholder-assignment/:ppID',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS]
  //   },
  //   loadChildren: () => import('./pages/planning-process-stakeholder-assignment/planning-process-stakeholder-assignment.module').then( m => m.PlanningProcessStakeholderAssignmentPageModule)
  // },
  // {
  //   path: ':langCode/planning-process/data-collection/:ppID',
  //   canActivate:[AuthGuard],
  //   data:{
  //     roles:[USER_ROLES.PROCESS_OWNER,USER_ROLES.SUPER_USERS,USER_ROLES.USERS]
  //   },
  //   loadChildren: () => import('./pages/planning-process-training-status/planning-process-training-status.module').then( m => m.PlanningProcessTrainingStatusPageModule)
  // },
  // {
  //   path: ':langCode/unauthorized',
  //   component: UnauthorizedComponent
  // },
  // {
  //   path: ':langCode/profile',
  //   canActivate:[AuthGuard],
  //   component: ProfileComponent
  // },
  {
    path: '**',
    component: PageNotFoundComponent
  },
 



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
