import { Component, OnInit } from '@angular/core';
import { trigger,  style, animate, transition } from '@angular/animations';
import { Observable } from 'rxjs';
import { MessageService } from './message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations:[
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9) translateY(5px)' }),
        animate('100ms', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0, transform: 'scale(0.9) translateY(5px)' })),
      ]),
    ]),
  ]
})
export class MessageComponent implements OnInit {
  message$:Observable<{show:boolean,title:string,message:string,note:string,type:string}>
  constructor(private messageService:MessageService) { }
 
  ngOnInit() {
   this.message$ =  this.messageService.message$
  }

  handleClose(){
   this.messageService.close()
  }
 
}
