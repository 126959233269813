import { Component, Input, OnInit,TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {

  
  @Input('listItems') listItems: any[]=[];
  @Input('selectedItems') selectedItems: any[]=[];
  @Input('searchOnKeys') searchOnKeys: string[]=[];
  @Input('disableItems') disableItems: string[]=[];
  @Input('itemTitleKey') itemTitleKey:string;
  @Input('itemSubTitleKey') itemSubTitleKey:string;
  @Input('itemRetunValueKey') itemRetunValueKey:string;
  @Input('disable') disable:boolean = false;
  @Input('multi') multi:boolean = true;
  @Input('templateRef') itemTemplateRef:TemplateRef<any>;
  @Input('headerTitle') headerTitle:string ='Select Options'
  @Input('translate') translate:boolean = false;

  searchQuery:string=''
  filteredListItems:any[]=[]
  localSelectedItems:any[]=[]
  localListItem=[]
  isAllSelected=false
  constructor(private modalCtr:ModalController) { }
 
  ngOnInit() {
    
    this.localListItem = this.listItems.filter(item=>!this.disableItems.includes(item[this.itemRetunValueKey]))
    // this.filteredListItems = [...this.listItems]
    this.filteredListItems = [...this.localListItem]

    this.localSelectedItems = [...this.selectedItems]
    if(this.localListItem.length == this.selectedItems.length){
      this.isAllSelected = true
    }
  }

  filterList(event:any){
   let _query  = event.target.value
   if(_query){
     this.filteredListItems = this.localListItem.filter(item=>{
       let found = false
       for(const searchKey of this.searchOnKeys){
         let match = item[searchKey].toLowerCase().indexOf(_query.toLowerCase())
         if(match > -1){
           found =true;
           break;
         }
       }
       return found
     })
   }else{
    //  this.filteredListItems = [...this.listItems]
    this.filteredListItems = [...this.localListItem]
   }
   this.isAllSelected = this.localListItem.length == this.localSelectedItems.length
  }
  toggleItem(item:any){
    let selectedValue  = item[this.itemRetunValueKey]
    let index = this.localSelectedItems.indexOf(selectedValue)
    if(this.multi){
      if(index > -1){
        //remove item if exist
        this.localSelectedItems = [...this.localSelectedItems.slice(0,index), ...this.localSelectedItems.slice(index+1)]
      }else{
        this.localSelectedItems.push(selectedValue)
      }
      this.isAllSelected = this.localListItem.length == this.localSelectedItems.length
    }else{
      if(index > -1){
        //remove item if exist make it empty in case of single selection
        this.localSelectedItems = []
      }else{
        this.localSelectedItems=[selectedValue]
      }
      this.confirm()
    }

  }
  removeItem(value:string){
    let index = this.localSelectedItems.indexOf(value)
    this.localSelectedItems = [...this.localSelectedItems.slice(0,index), ...this.localSelectedItems.slice(index+1)]

  }


  isInSelectedList(item:any){
    return this.localSelectedItems.find(value => value === item[this.itemRetunValueKey] )
  }

  isInDisableList(item:any){
    return this.disableItems.indexOf(item[this.itemRetunValueKey]) > -1
  }
  toggleAll(){
    this.isAllSelected = !this.isAllSelected
    if(this.isAllSelected){
      this.localSelectedItems = this.localListItem.map(item=>item[this.itemRetunValueKey])
    }else{
      this.localSelectedItems  = []
    }
  }
  clearAndSearch(){
    this.searchQuery = null
  }
  async cancel(){
     await this.modalCtr.dismiss(null,'cancel','searchable-select-modal')
  }
  async confirm(){
    await this.modalCtr.dismiss(this.localSelectedItems,'confirm','searchable-select-modal')
  }
}
