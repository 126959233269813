import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'CMI-paginator',
  templateUrl: './cmi-paginator.component.html',
  styleUrls: ['./cmi-paginator.component.scss'],
})
export class CMIPaginatorComponent   {


    @Input()  currentPage=0
    @Input()  itemsPerPage=0
    @Input()  totalItemsCurrentPage=0
    @Input() totalItems=0
    @Input() pageSizes = [5,25,50,100,200]
    @Output() pageChange = new EventEmitter<number>()
    @Output() pageSizeChange = new EventEmitter<number>()

  hangePageChange(pageNo:number){
    this.pageChange.emit(pageNo)
  }
  onPageSizeChange(event){
    this.pageSizeChange.emit(+event.detail.value)
  
  }

  get startPerPage(){
    if(this.totalItems==0) return 0;
    return this.itemsPerPage*(this.currentPage-1)+1;
  }
  get endPerPage(){
    if(this.totalItems==0) return 0;
     return this.startPerPage + this.totalItemsCurrentPage -1;
  }
 }
