import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
private requestCount = 0;
private loader = new BehaviorSubject<boolean>(false)
loader$ = this.loader.asObservable()
 
  showLoader(){
    this.requestCount = this.requestCount + 1
    if(this.requestCount ==1){
      this.loader.next(true)
    }
  }
  hideLoader(){
    this.requestCount = this.requestCount - 1 
    if(this.requestCount <= 0  )
      {
        this.requestCount=0
        this.loader.next(false)
      }
}
resetLoadeer(){
  this.requestCount=0;
  this.loader.next(false)

}

}
