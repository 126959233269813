
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, ObservableInput, throwError } from 'rxjs';
import { LoginService } from '../services/login-service/login.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { MessageService } from '../shared/components/message/message.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private tokenExceptionList = [
    environment.oauth2.tokenUrl
  ];
  constructor(private loginService:LoginService,private messageService:MessageService,private storageService: StorageService,private router:Router,) {}
 
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newReq = this.getUpdatedRequest(request);
    return next.handle(newReq).pipe(
      catchError((err) => {
        const refreshToken = this.storageService.getObjectFromStorage('refreshToken');
        if ( err instanceof HttpErrorResponse && err.status === 401 && refreshToken != null ) {
          return this.manage401ErrorCode(request, next, refreshToken);
        } else {
          return this.handleError(err,request);
        }
      }),
    );
  }





  // add tokens to header and clone the request
  getUpdatedRequest(req: HttpRequest<any>) {
   const idToken = this.storageService.getObjectFromStorage('idToken');
    let headers = req.headers;
    if ( headers && headers != null && idToken && idToken != null &&  !this.isUrlInException(req.url) && req.url.indexOf(environment.bucketURL) == -1) {
      headers = headers.set('Authorization', idToken);
    }
    return req.clone({ headers });
  }

  // method responsible to manage 401 Unauthorized error.
  // In case of unauthorized error, this logic makes attempt
  // to get new tokens based on refresh token already available.
 

  manage401ErrorCode(
    req: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): ObservableInput<any> {

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.loginService.getTokenFromRefreshToken(refreshToken).pipe(
        switchMap((newToken: any) => {
          if (newToken) {
            // // allow original request process again with new Tokens set in header
            const { id_token, access_token } = newToken;
            this.storageService.setObjectInStorage('accessToken',access_token) 
            this.storageService.setObjectInStorage('idToken', id_token) 
            this.tokenSubject.next(id_token);

            // allow original request process again with new Tokens set in header
            return next.handle(this.getUpdatedRequest(req));

          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser('switch map --after if block');
        }),
        catchError((error) => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser('catch error block');
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      // this logic holds of any request from processing until the refresh token is retrieved.
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.getUpdatedRequest(req));
        })
      );
    }
  }




  // Houses logic to execute to force user to logout.
  logoutUser(trace?) {
    // Route to the login page
   sessionStorage.setItem('redirectionUrl',location.pathname)
   confirm('Session is timed out. You are required to login again. Please click OK to navigate to login page');
    this.loginService.logout();
    this.storageService.clearFullStorage();
    window.location.href = environment.oauth2.redirectUri+'/login';
    return this.handleError('Session Timed Out');
  }

  logout(): void {
    this.loginService.logout();
    this.storageService.clearFullStorage();
    // tslint:disable-next-line: max-line-length
    window.location.href = environment.logoutUrl;
  }
  // Check if the URL is in Exception list
  isUrlInException(url) {
    let isInException = false;

    this.tokenExceptionList.forEach((e) => {
      if (e && e != null && url.indexOf(e) !== -1) {
        isInException = true;
      }
    });

    return isInException;
  }
 // Houses logic to handle http error
  handleError(error: any,req?: HttpRequest<any>) {
    console.log(error)
    if (error?.error?.message === "The incoming token has expired") {
          window.location.href =environment.authentication_url + window.localStorage.getItem('idp')
          
    } else if (error?.error?.message !== undefined) {
      if(!this.isUrlInException(req?.url) && req?.url.indexOf(environment.bucketURL) == -1){
        this.messageService.error(error.error.message)
      }
    } 
    else if (error?.error !== undefined) {
      if(!this.isUrlInException(req?.url) && req.url.indexOf(environment?.bucketURL) == -1){
        this.messageService.error(`${error.message}`)
      }
    }
    return throwError(error);
  }
}