import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoaderComponent} from './loader.component'



@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,

  ],
  exports:[LoaderComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
]
})
export class LoaderModule { }
