import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

private message = new BehaviorSubject<any>({show:false,title:'',message:'',note:null,type:''})
message$ = this.message.asObservable()
  open(title:string='',message:string='',note = null,type:string=''){
    this.show(title,message,note,type)
  }
  success(message:string='',note = null,title:string='Success',type:string='success'){
    this.show(title,message,note,type)
   
   }
   error(message:string='',note = null,title:string='Error',type:string='error'){
    this.show(title,message,note,type)
   }
   warning(message:string='',note = null,title:string='Warning',type:string='warning'){
    this.show(title,message,note,type)
   }
  close(){
    this.message.next(
        {show:false,title:'',note :'',message:'',type:''}
       )
}
  show(title,message,note,type){
    this.message.next(
      {show:true,title,message,note,type}
     )
  }
}
