import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  loader$:Observable<boolean>;
  constructor(private loaderService:LoaderService) { }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$
  }

}
