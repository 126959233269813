import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
   navigationExtraState:any={}
   showRegistrationLinks=false
   rrmRegisterURL=''
   iamRegisterURL=''
   loginProvider=''
   currentLang = window.localStorage.getItem('lang') || 'en'
  constructor(private router : Router,private storageService:StorageService) { }

  ngOnInit() {
    this.rrmRegisterURL=environment.rrmRegisterURL
    this.iamRegisterURL = environment.iamRegisterURL
    this.navigationExtraState =  this.router.getCurrentNavigation().extras?.state
    if(this.navigationExtraState){
      this.showRegistrationLinks = this.navigationExtraState.showRegistrationLinks ? true : false
      this.loginProvider = this.navigationExtraState.loginProvider
    }else{
      this.showRegistrationLinks = false
    }
  }
  goHome(hasAppAccess){

    if(!hasAppAccess){
      this.storageService.clearFullStorage()
      window.localStorage.clear()
      window.sessionStorage.clear()
      let logoutUrl =  this.loginProvider === 'internal' ? environment.logoutAzureUrl: environment.logoutIAMUrl
      window.location.href = logoutUrl;
      return
    }else{
      this.router.navigate([`/${this.currentLang}/dashboard`], {
        replaceUrl: true,
      });
    }
  }

}
