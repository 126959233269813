export const STATUS ={
    'AWAITING_TOP_LEVEL_ASSESSMENT':'awaiting top level assessment',
    'AWAITING_SELF_ASSESSMENT':'awaiting self assessment',
    'AWAITING_SWAP_ASSESSMENT': 'awaiting swap assessment',
    'AWAITING_CENTRAL_SWAP_ASSESSMENT':'awaiting central swap assessment',
    'AWAITING_CENTRAL_SELF_ASSESSMENT':'awaiting central self assessment',
    'AWAITING_CHANNEL_SWAP_ASSESSMENT':'awaiting channel swap assessment',
    'AWAITING_CHANNEL_SELF_ASSESSMENT':'awaiting channel self assessment',
    'AWAITING_FINAL_OUTCOME': 'awaiting final outcome',
    'AWAITING_ACTION_PLAN':'awaiting action plan',
    'AWAITING_SET_PRIORITY': 'awaiting set priority',
    'COMPLETED':'completed',
    'OFF_TRACK_WITH_NO_RECOVERY_PLAN':'off track with no recovery plan',
    'OFF_TRACK_WITH_RECOVERY_PLAN': 'off track with recovery plan',
    'ON_TRACK':'on track',
    'CANCELLED':'cancelled',
    'CLOSED':'closed',
    'DRAFT':'draft',
    'NEW':'new',
    'INITIATED':'initiated',
    'SUBMITTED':'submitted',
    'IMMEDIATE':'Immediate',
    'STRATEGIC':'Strategic',
    'FOR_LATER_CONSIDERATION':'For Later Consideration',
    'ON_DECK':'On Deck',
    'DELETED':'deleted',
    'AWAITING_STAKEHOLDER_ASSIGNMENT' :'awaiting stakeholder assignment',
    'AWAITING_DATA_COLLECTION':'awaiting data collection',
    'AWAITING_PRIORITY_CHECK':'awaiting priority check',

}
   
export const STATUS_MAP = {
    [STATUS.AWAITING_TOP_LEVEL_ASSESSMENT]:{label:'Awaiting Top Level Assessment',className:'chip-success'},
    [STATUS.AWAITING_SELF_ASSESSMENT]:{label:'Awaiting Self Assessment',className:'chip-warning'},
    [STATUS.AWAITING_SWAP_ASSESSMENT]:{label:'Awaiting Swap Assessment',className:'chip-danger'},
    [STATUS.AWAITING_CENTRAL_SWAP_ASSESSMENT]:{label:'Awaiting Central Swap Assessment',className:'chip-danger'},
    [STATUS.AWAITING_CENTRAL_SELF_ASSESSMENT]:{label:'Awaiting Central Self Assessment',className:'chip-warning'},
    [STATUS.AWAITING_CHANNEL_SWAP_ASSESSMENT]:{label:'Awaiting Channel Swap Assessment',className:'chip-danger'},
    [STATUS.AWAITING_CHANNEL_SELF_ASSESSMENT]:{label:'Awaiting Channel Self Assessment',className:'chip-warning'},
    [STATUS.AWAITING_FINAL_OUTCOME]:{label:'Awaiting Final Outcome',className:'chip-orange'},
    [STATUS.AWAITING_ACTION_PLAN]:{label:'Awaiting Action Plan',className:'chip-tertiary'},
    [STATUS.AWAITING_SET_PRIORITY]:{label:'Awaiting Set Priority',className:'chip-tertiary'},
    [STATUS.COMPLETED]:{label:'Completed',className:'chip-success'},
    [STATUS.SUBMITTED]:{label:'Submitted',className:'chip-tertiary'},
    'off track with no recovery plan':{label:'Off Track With No Recovery Plan',className:'chip-danger'},
    'awaiting central approval':{label:'Awaiting Central Approval',className:'chip-tertiary'},
    'off track with recovery plan':{label:'Off Track With Recovery Plan',className:'chip-warning'},
    'on track':{label:'On Track',className:'chip-tertiary'},
    'cancelled':{label:'Cancelled',className:'chip-danger'},
    'deleted':{label:'Deleted',className:'chip-danger'},
    'closed':{label:'Closed',className:'chip-success'},
    [STATUS.NEW]:{label:'New',className:'chip-dark'},
    [STATUS.INITIATED]:{label:'Initiated',className:'chip-warning'},
    [STATUS.DRAFT]:{label:'Draft',className:'chip-dark'},
    [STATUS.STRATEGIC]:{label:'Strategic',className:'chip-danger'},
    [STATUS.IMMEDIATE]:{label:'Immediate',className:'chip-warning'},
    [STATUS.FOR_LATER_CONSIDERATION]:{label:'For Later Consideration',className:'chip-tertiary'},
    [STATUS.ON_DECK]:{label:'On Deck',className:'chip-success'},
    [STATUS.AWAITING_STAKEHOLDER_ASSIGNMENT]:{label:'Awaiting Stakeholder Assignment',className:'chip-success'},
    [STATUS.AWAITING_DATA_COLLECTION]:{label:'Awaiting Data Collection',className:'chip-warning'},
    [STATUS.AWAITING_PRIORITY_CHECK]:{label:'Awaiting Priority Check',className:'chip-danger'},


};


export const USER_ROLES={
    PROCESS_OWNER:'Edge 360 - Process Owner',
    CONTENT_OWNERS:'Edge 360 - Content Owners',
    SUPER_USERS:'Edge 360 - Super Users',
    USERS:'Edge 360 - Users',
    VIEWERS:'Edge 360 - Viewers'

}

export const OWNERSHIP_TYPES={
    COMPANY_OWNED:"Company Owned",
    INDEPENDENT:"Independent",
    JV:"JV",
    CUMMINS_OWNED:"Cummins Owned"
}
export const OWNERSHIP_TYPES_DROPDOWNS = [OWNERSHIP_TYPES.COMPANY_OWNED,OWNERSHIP_TYPES.INDEPENDENT,OWNERSHIP_TYPES.CUMMINS_OWNED,OWNERSHIP_TYPES.JV]
export const TABLE={
    REGION:'region',
    TERRITORY:'territory',
    COMPETENCY:'competency',
    CAPABILITY:'capability',
    FUNCTION:'function',
    SEGMENT:'segment',
    CHARACTERISTICS:'characteristics',
}
export const GAPS={
    TOTAL_CAPABILITY:"Total Capability",
    PARTIAL_CAPABILITY:"Partial Capability",
    CAPABILITY_GAP :"Capability Gap",
    NOT_REQIURED:"Not Required"
}
export const GAPS_DROPDOWNS = [GAPS.TOTAL_CAPABILITY,GAPS.PARTIAL_CAPABILITY,GAPS.CAPABILITY_GAP,GAPS.NOT_REQIURED]

export const GAPS_BG_COLORS = {
   [GAPS.TOTAL_CAPABILITY]: {'color':'#333333','background':'#E0FFEB'},
   [GAPS.PARTIAL_CAPABILITY]:{'color':'#333333','background':'#FFF7E5'},
   [GAPS.CAPABILITY_GAP]:{'color':'#333333','background':'#FDE7E5'},
   [GAPS.NOT_REQIURED]:{'color':'#333333','background':'#FFFFFF'}
}

export const REGIONS_MAP={
        'US and Canada'	:'CSSNA',
        'Latin America':'LATAM',
        'Mexico':'LATAM',
        'Africa':'AME',
        'Middle East':'AME',
        'CIS':'AME',
        'Europe':'Europe',
        'South Pacific':'Asia Pacific',
        'Japan':'Asia Pacific',
        'Korea':'Asia Pacific',
        'Southeast Asia':'Asia Pacific',
        'East Asia':'East Asia',
        'India':'India'
}
export const RRM_TO_IAM_REGION_MAP = {
    'CSSNA' : ['US and Canada'],
    'LATAM':['Latin America','Mexico'],
     'AME':['Africa','Middle East','CIS'],
    'Europe':['Europe'],
    'Asia Pacific':['South Pacific','Japan','Korea','Southeast Asia'],
    'East Asia':['East Asia'],
    'India':['India']
}
export const FOCUS={
    'CENTRAL':"Central",
    'CHANNEL':'Channel',
    'EITHER':'Either'
}
export const GENERAL_CONST ={
    DEFAULT_PAGE_SIZE : 5,
    PAGE_SIZES_MAP :'pageSizesMap'
}