import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-horizontal-tab',
  templateUrl: './horizontal-tab.component.html',
  styleUrls: ['./horizontal-tab.component.scss'],
})
export class HorizontalTabComponent {
  @Input() tabs=[]
  @Input() completedTabs=[]
  @Input() activeTab
  @Output() onTabClicked = new EventEmitter<string>()
  @ViewChild('horizontalTabsRef',{static:false}) horizontalTabsRef:ElementRef<HTMLElement>;
  isLeftArrowVisible=true
  isRightArrowVisible=true

  _onTabClicked(_tabName,i){
    this.onTabClicked.emit(_tabName)
  }
  // slide(direction){
  //   console.log(direction)
  // }

  iconVisibility(){
    let scrollLeftValue = Math.ceil(this.horizontalTabsRef.nativeElement.scrollLeft)
    let scrollableWidth = this.horizontalTabsRef.nativeElement.scrollWidth - this.horizontalTabsRef.nativeElement.clientWidth
    console.log({scrollLeftValue,scrollableWidth,scrollWidth: this.horizontalTabsRef.nativeElement.scrollWidth,clientWidth: this.horizontalTabsRef.nativeElement.clientWidth})
    this.isLeftArrowVisible =  scrollLeftValue > 80
    this.isRightArrowVisible = (scrollableWidth  )> scrollLeftValue + 80
    console.log(scrollLeftValue,scrollableWidth)
    console.log( this.isLeftArrowVisible,this.isRightArrowVisible)

  }
  slide(direction){
 
    // let scrollableWidth = this.horizontalTabsRef.nativeElement.scrollWidth - this.horizontalTabsRef.nativeElement.clientWidth
   
  
    if(direction == 'left'){
      this.horizontalTabsRef.nativeElement.scrollLeft -= 200
    }
    if(direction == 'right'){
      this.horizontalTabsRef.nativeElement.scrollLeft += 200
    }

    // setTimeout(()=>this.iconVisibility(),100)
  
  }
}
