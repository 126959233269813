import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject,throwError } from 'rxjs';
import jwt from 'jwt-decode';
import { StorageService } from '../storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { TokenUserInfo } from 'src/app/models/TokenUserInfo';
import { Tokens } from 'src/app/models/Tokens';
import { USER_ROLES,REGIONS_MAP ,GENERAL_CONST} from 'src/app/constants/Constants';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  URL = environment.apiHostUrl;
  HOME_PAGE_URL = '/dashboard';

  currentLang = window.localStorage.getItem('lang') || 'en'
  private isLoggedIn: any = false;
  private isForRegistration = false;
  private userSubject = new BehaviorSubject<User>(null);
  private tokenSubject = new BehaviorSubject<Tokens>(null);
  private isLogggedInSubject = new BehaviorSubject<boolean>(null);
  isLoggedIn$=this.isLogggedInSubject.asObservable()
  userInfo$=this.userSubject.asObservable()
 
  constructor(@Inject(DOCUMENT) private document: any, private http: HttpClient, 
  private storageService: StorageService,private router:Router,private commonService:CommonService ) { 
    let userInfo = this.getUserInfo()
    if(userInfo){
      this.userSubject.next(userInfo)
      this.isLogggedInSubject.next(true)
    }
  }

  private setIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this.storageService.setInStorage('isLoggedIn', this.isLoggedIn)
  }

  private setUserInfo(user: User) {
    this.userSubject.next(user);
    this.storageService.setObjectInStorage('userInfo', user)
  }
  public getIdToken() {
    const tokens = this.tokenSubject.getValue();
    let idToken: any = tokens && tokens != null ? tokens.idToken : null;
    if (idToken === null && this.storageService.getObjectFromStorage('idToken') !== null) {
      idToken = this.storageService.getObjectFromStorage('idToken');
    
    }
  
    return idToken;
  }
  public getAccessToken() {
    const tokens = this.tokenSubject.getValue();
    let accessToken = tokens && tokens != null ? tokens.accessToken : null;
    if (accessToken === null && this.storageService.getObjectFromStorage('accessToken') !== null) {
      accessToken = this.storageService.getObjectFromStorage('accessToken');
    }
    return accessToken;
  }
  public getRefreshToken() {
    const tokens = this.tokenSubject.getValue();
    let refreshToken = tokens && tokens != null ? tokens.refreshToken : null;
    if (refreshToken === null && this.storageService.getObjectFromStorage('refreshToken') !== null) {
      refreshToken = this.storageService.getObjectFromStorage('refreshToken');
    }
    return refreshToken;
  }
  public isRegistration() {
    return this.isForRegistration;
  }
  public getUserInfo() {
    return this.storageService.getObjectFromStorage('userInfo')
  }
  public getUserRoleId() {
    let roleId: any
    const userObj = this.userSubject.getValue();
    if (userObj && userObj != null) {
      roleId = userObj.roleId
    } else {
      roleId = -1
    } return roleId;
  }

  public getUserRoleName() {
    let roleName: any
    const userObj = this.userSubject.getValue();
    if (userObj && userObj != null) {
      roleName = userObj.roleName
    } else {
      roleName = -1
    } return roleName;
  }

  setTokens(tokens: Tokens) {
    this.tokenSubject.next(tokens);
    let tokens_access: any = tokens;
    if (tokens_access !== undefined && tokens_access !== null) { this.storageService.setObjectInStorage('accessToken', tokens_access["accessToken"]) }
    if (tokens_access !== undefined && tokens_access !== null) { this.storageService.setObjectInStorage('idToken', tokens_access["idToken"]) }
    if (tokens_access !== undefined && tokens_access !== null &&
      tokens_access.refreshToken !== undefined && tokens_access.refreshToken !== null) { this.storageService.setObjectInStorage('refreshToken', tokens_access["refreshToken"]) }
  }


  public updateTokens(accessToken: string, idToken: string) {
    const tokens: Tokens = this.tokenSubject.getValue();
    if (idToken && idToken != null && idToken.trim().length > 0) {
      tokens.idToken = idToken;
    }
    if (accessToken && accessToken != null && accessToken.trim().length > 0) {
      tokens.accessToken = accessToken;
    }
    this.tokenSubject.next(tokens);
    this.storageService.setInStorage('accessToken',accessToken) 
     this.storageService.setInStorage('idToken', idToken) 
    
  }




  public logout() {
    this.setUserInfo(null);
    this.setTokens(null);
    this.setIsLoggedIn(false);
    this.storageService.clearFullStorage()
  }

   // Fetch tokens based on code provided in authorization code flow
   public fetchTokensForCode(code: string) {
   
    if (code && code != null && code.trim().length > 0) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      let langSpecificRedirectUri = window.location.origin
      const params: HttpParams = new HttpParams()
        .append('grant_type', environment.oauth2.grantType)
        .append('client_id', environment.oauth2.clientId)
        .append('redirect_uri', langSpecificRedirectUri)
       // .append('redirect_uri', environment.oauth2.redirectUri)
        // .append('client_secret', environment.oauth2.clientSecret)
        .append('code', code);

      return this.http.post(environment.oauth2.tokenUrl, null, {
        headers,
        params,
      });
    }
    return throwError('auth code not provided');
  }
 // fetch access token from refresh token.
 public getTokenFromRefreshToken(refreshToken: string) {
  if (
    refreshToken &&
    refreshToken != null &&
    refreshToken.trim().length > 0
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let langSpecificRedirectUri = window.location.origin
    let params = new HttpParams();
    params = params.set('grant_type', 'refresh_token');
    params = params.set('client_id', environment.oauth2.clientId);
    params = params.set('refresh_token', refreshToken);
    params = params.set('redirect_uri', langSpecificRedirectUri);
    //params = params.set('redirect_uri', environment.oauth2.redirectUri);
    params = params.set('scope', environment.oauth2.scope);
    return this.http.post(environment.oauth2.tokenUrl,null, { headers,params });
  }
  return throwError('refreshToken not provided');
}
  // Navigate to home page or redirect 
  public navigateToHomePage() {
    const redirectUrl =  sessionStorage.getItem('redirectionUrl')
    if (redirectUrl && redirectUrl != null && redirectUrl.trim().length > 0) {
      const rd = redirectUrl.slice(0);
      sessionStorage.removeItem('redirectionUrl')
      this.router.navigate([rd]);
    } else {
       this.router.navigate([`${this.currentLang}/dashboard`]);
    }
  }
  // Navigate to Unauthorized Page
  public navigateToUnauthorized() {
    // this.setIsLoggedIn(false);
    // this.setTokens(null);
    // this.setUserInfo(null);
    let lp = localStorage.getItem('loginProvider')
    this.storageService.clearFullStorage()
    // localStorage.setItem('loginProvider',lp)
    const data:NavigationExtras={
      state:{
       showRegistrationLinks:true,
       loginProvider : lp
      }
    }
    this.router.navigate([`${this.currentLang}/unauthorized`],data);
    
  }

  hasRole(roles:string[]){
    return roles.includes(this.getUserRoleName().trim())
    
  }
  public authorize(tokens) {
    const { id_token } = tokens;
    const decodedToken: any = jwt(id_token);
    if (decodedToken && decodedToken != null){
          // check if the user was authenticated from authorized source
          // If no, then redirect to unauthorized page
          if (decodedToken.aud !== environment.oauth2.clientId) {
            this.navigateToUnauthorized();
            return;
            
          }
       let userInfo ={}
       let user: User 
       let tokenUserInfo

       if(decodedToken.idp == 'AzureAD' && decodedToken.roles != null){
          // create token object and set the extracted information
          const verifiedTokens: Tokens = new Tokens(tokens);
          let azureAdInfo =  {
                  nickname: decodedToken.nickname,
                  given_name: decodedToken.given_name,
                  family_name: decodedToken.family_name,
                  email: decodedToken.email,
                  idp:decodedToken.idp
              }
          
          tokenUserInfo = new TokenUserInfo(azureAdInfo);
      
          let parsedToken = JSON.parse(decodedToken.roles);
          verifiedTokens.setTokenUserInfo(tokenUserInfo);
          this.setTokens(verifiedTokens);
  
      if (
        verifiedTokens &&
        verifiedTokens.tokenUserInfo &&
        verifiedTokens.tokenUserInfo != null &&
        verifiedTokens.tokenUserInfo.nickname &&
        verifiedTokens.tokenUserInfo.nickname != null
      ) {
         userInfo = {
          "wwid": decodedToken.nickname,
          "userNickName":decodedToken.nickname,
          "firstName": decodedToken.given_name,
          "lastName": decodedToken.family_name,
          "userEmail": decodedToken.email,
          "roleId": parsedToken[0].roleId,
          "idp":decodedToken.idp,
          "roleName": parsedToken[0].roleName,
          "userRegion": [],
          "userTerritory": null,
          "userFocus": null
        }

        if(parsedToken[0].attributeList){
          ["Region","Focus","Territory"].forEach((attr)=>{
           let obj =  parsedToken[0].attributeList.find((item)=>{
              return item.hasOwnProperty(attr)
            })
          if(obj){
              let keyVal = Object.entries(obj)[0] // ['region','india']
              if(keyVal[0] == 'Region'){
                userInfo['userRegion'] = Array.isArray(keyVal[1])? keyVal[1] : [keyVal[1]]
              }
              if(keyVal[0] == 'Focus'){
                userInfo['userFocus'] = keyVal[1]
              }
              if(keyVal[0] == 'Territory'){
                userInfo['userTerritory'] = keyVal[1]
              }
          }else{
  
            if(attr == 'Region'){
              userInfo['userRegion'] = []
            }
            if(attr == 'Focus'){
              userInfo['userFocus'] = null
            }
            if(attr == 'Territory'){
              userInfo['userTerritory'] = null
            }
          }
          })
         
        }
      } else {
        this.navigateToUnauthorized();
        return
      }
      }else if(decodedToken.idp == 'Salesforce' && decodedToken.SFRoles != null &&  (decodedToken.SFRoles.includes('Central') || decodedToken.SFRoles.includes('Channel'))){
      // create token object and set the extracted information
      const verifiedTokens: Tokens = new Tokens(tokens);
      let salesforceInfo =  {
              nickname: decodedToken['custom:username'],
              given_name: decodedToken.given_name,
              family_name: decodedToken.family_name,
              email: decodedToken.email,
              idp:decodedToken.idp
          }
      
      tokenUserInfo = new TokenUserInfo(salesforceInfo);
      verifiedTokens.setTokenUserInfo(tokenUserInfo);
      this.setTokens(verifiedTokens);

  if (
    verifiedTokens &&
    verifiedTokens.tokenUserInfo &&
    verifiedTokens.tokenUserInfo != null &&
    verifiedTokens.tokenUserInfo.nickname &&
    verifiedTokens.tokenUserInfo.nickname != null
  ) {

    let focus = null
    if(decodedToken.SFRoles.includes('Central')){
      focus = 'Central'
    }
    if(decodedToken.SFRoles.includes('Channel')){
      focus = 'Channel'
    }
    let region = REGIONS_MAP[decodedToken['custom:SFRegion']]
     userInfo = {
      "userNickName":decodedToken['custom:username'],
      "firstName": decodedToken.given_name,
      "lastName": decodedToken.family_name,
      "userEmail": decodedToken.email,
      "roleId": null,
      "idp":decodedToken.idp,
      "roleName": USER_ROLES.USERS,
      "userRegion": region ? [region]: [],
      "userTerritory": null,
      "userFocus": focus
    }
   
  } else {
   
    this.navigateToUnauthorized();
    return
  }
    }else {
      this.navigateToUnauthorized();
      return
    }

    // if everything is ok 
    user= new User(userInfo);
    user.setTokenUserInfo(tokenUserInfo);
    this.setUserInfo(user);
    this.isLogggedInSubject.next(true);
    this.setIsLoggedIn(true);
    if(!this.storageService.getObjectFromStorage('pageSizesMap')){
      let defaultPageSize = GENERAL_CONST.DEFAULT_PAGE_SIZE
      let pageSizes ={
        'dashboard' : defaultPageSize,
        'planning_process' : defaultPageSize,
        'action_plan' : defaultPageSize,
        'set_priority' : defaultPageSize,
        'master_data' : defaultPageSize,
      }
      this.storageService.setObjectInStorage('pageSizesMap',pageSizes)
    }
   
    this.navigateToHomePage();

  } else {
    this.navigateToUnauthorized();
  }
}
}