import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  currentLang = window.localStorage.getItem('lang') || 'en'
  constructor(private router : Router) { }
  goHome(){
    this.router.navigateByUrl(`/${this.currentLang}/dashboard`)
  }

}
