import { TokenUserInfo } from './TokenUserInfo';
export class User {
    id: string;
    firstName: string;
    lastName:string;
    wwid: string;
    email: string;
    roleId: number;
    role: string;
    dWwid:string;
    justification?:string;
    action?:string;
    delegator:any;
    userEmail: string;
   roleName : string;
   accessStatus:string;
   userFocus:string;
   userRegion:string;
   userTerritory:string;
   userNickName:string;
   idp:string;

    constructor(data) {
        this.wwid = data.wwid;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.userEmail = data.userEmail;
        this.roleId = data.roleId;
        this.roleName = data.roleName;
        this.accessStatus = data.accessStatus;
        this.userFocus=data.userFocus
        this.userRegion=data.userRegion
        this.userTerritory=data.userTerritory
        this.userNickName=data.userNickName
        this.idp=data.idp
      }
    

    

    tokenUserInfo?: TokenUserInfo;

    setTokenUserInfo(tokenUserInfo: TokenUserInfo) {
        this.tokenUserInfo = tokenUserInfo;
    }

    getTokenUserInfo() {
        return this.tokenUserInfo;
    }

    

}


export interface Roles{
    role_id: number;
    role_name: string;
}

export class RegUser {
    firstName?: string;
    lastName?:string;
    wwid?: string;
    email?: string;
}
