import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profile:any={}
  currentLang = window.localStorage.getItem('lang') || 'en'
  constructor(private router : Router,private storageService: StorageService) { }
  
  ngOnInit() {
    this.profile = this.storageService.getObjectFromStorage('userInfo')
  }
  goHome(){
    this.router.navigate([`/${this.currentLang}/dashboard`]);
  }

}
