const authDomainUrl = 'https://gdc-rrm-dev-pool.auth.us-east-1.amazoncognito.com';
const responseType = 'code';
const scope = 'openid+profile';
const clientId = '7fg4mrmiflmf7ddui10i85ulo6';
const redirectUri = 'https://edge360-dev.cummins.com';
const rrmURL = 'https://rolerequestmanager-dev.cummins.com';
const rrmAppId='edge';
const rrmRegisterURL = `${rrmURL}/request-access?appId=${rrmAppId}`;
const authentication_url= `${authDomainUrl}/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=`
export const environment = {
  bucketURL: `edge360-storage-dev`,
  name: "dev",
  production: false,
  contentTypeKey: 'Content-Type',
  contentTypeValue: 'application/x-www-form-urlencoded',
  rrmURL:`${rrmURL}`,
  rrmRegisterURL: `${rrmRegisterURL}`,
  azureProvider : 'AzureAD',
  salesForceProvider:'Salesforce',
  iamRegisterURL:'https://cumminscss--csodev.sandbox.my.site.com/cw/IAM_Authorize?appid=a1aDS000001vS3M',
  apiHostUrl: `${redirectUri}/api`,
  loginPageUrl:redirectUri,
  logoutUrlExternal:'https://cumminscss--csodev.sandbox.my.site.com/cw/IAM_logout',
  logoutUrl: 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0',
  authentication_url: `${authDomainUrl}/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=`,
  azure_authentication_url:`${authentication_url}AzureAD`,
  iam_authentication_url:`${authentication_url}Salesforce`,
  logoutAzureUrl:`${authDomainUrl}/logout?client_id=${clientId}&logout_uri=https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0`,
  logoutIAMUrl:`${authDomainUrl}/logout?client_id=${clientId}&logout_uri=https://cumminscss--csodev.sandbox.my.site.com/cw/IAM_logout`,
  oauth2: {
    loginUrl: `${authDomainUrl}/oauth2/authorize`,
    redirectUri: `${redirectUri}`,
    tokenUrl: `${authDomainUrl}/oauth2/token`,
    clientId: `${clientId}`,
    scope: `${redirectUri}/api+openid+profile`,
    responseType: `${responseType}`,
    grantType: 'authorization_code'
  },
  iamAccessManagement:"#"

};
