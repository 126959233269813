import { Component, Input,TemplateRef,Output,forwardRef ,ContentChild, EventEmitter} from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PopupComponent } from './popup/popup.component';

@Component({
  selector: 'app-searchable-multi-select',
  templateUrl: './searchable-multi-select.component.html',
  styleUrls: ['./searchable-multi-select.component.scss'],
  providers:[
    {
      provide:NG_VALUE_ACCESSOR,
      useExisting:forwardRef(()=>SearchableMultiSelectComponent),
      multi:true
    }
  ]
})
export class SearchableMultiSelectComponent implements  ControlValueAccessor {

  constructor(private modalCtr:ModalController) { }
  private onChange:Function;
  private onTouched:Function;

  selectedItems:any[]=[];
  

  @Input('list') list: any[]=[];
  @Input('searchOnKeys') searchOnKeys: string[]=[];
  @Input('disableItems') disableItems: string[]=[];
  @Input('itemTitleKey') itemTitleKey:string;
  @Input('itemSubTitleKey') itemSubTitleKey:string;
  @Input('itemRetunValueKey') itemRetunValueKey:string;
  @Input('disabled') disabled:boolean = false;
  @Input('multi') multi:boolean = true;
  @Input('headerTitle') headerTitle:string = 'Select Options';
  @Input('cssStyles') cssStyles:any = {};
  @Input('showSelectionBelow') showSelectionBelow:boolean = false;
  @Input('showCount') showCount:boolean = false;
  @Input('translate') translate:boolean = false;
  @Input('containerStyle') containerStyle:any = {}
  @Output() change = new EventEmitter<any>()


  @ContentChild('itemTemplateRef',{static:false}) itemTemplateRef : TemplateRef<any>;

  async openPopup(){
     const modal = await this.modalCtr.create({
       component:PopupComponent,
       id:'searchable-select-modal',
       cssClass:['small-modal','searchable-select-modal'],
       componentProps:{
        listItems: this.list,
        selectedItems:this.selectedItems,
        multi:this.multi,
        templateRef:this.itemTemplateRef,
        disableItems:this.disableItems,
        itemTitleKey:this.itemTitleKey,
        itemSubTitleKey:this.itemSubTitleKey,
        itemRetunValueKey:this.itemRetunValueKey,
        searchOnKeys:this.searchOnKeys,
        headerTitle:this.headerTitle,
        translate:this.translate

       }
     })
     await modal.present();

     const {data,role} = await modal.onDidDismiss()

     if(role == 'confirm'){

      this.selectedItems = data;
      this.onTouched()
      if(this.multi){
        this.onChange(this.selectedItems)
        this.change.emit(this.selectedItems)
      }else{
        this.onChange(this.selectedItems[0] || null)
        this.change.emit(this.selectedItems[0] || null)
      }
   }

     }
  removeItem(value:string){
    let index = this.selectedItems.indexOf(value)
    this.selectedItems = [...this.selectedItems.slice(0,index), ...this.selectedItems.slice(index+1)]
    this.onTouched();
    this.onChange(this.multi ?  this.selectedItems : null)
    this.change.emit(this.multi ?  this.selectedItems : null)
  }
  removeAllItem(event){
    event.stopPropagation()
    this.selectedItems = []
    this.onTouched();
    this.onChange(this.multi ?  this.selectedItems : null)
    this.change.emit(this.multi ?  this.selectedItems : null)
  }
  writeValue(values: string[] | string): void {
      if(Array.isArray(values)){
       this.selectedItems = values;
      }else{
         this.selectedItems = ![null,undefined,''].includes(values) ? [values] : [];
      }
  }
  registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
  }
}
