import { Directive, Input, TemplateRef, ViewContainerRef,OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/authorization-details/auth-and-user-details.service';
import { LoginService } from 'src/app/services/login-service/login.service';
import {USER_ROLES} from 'src/app/constants/Constants'

@Directive({
  selector: '[appHasRole]'
})
// export class HasRoleDirective implements OnInit {
//   @Input('appHasRole') roles: string[];

//   constructor(private templateRef:TemplateRef<any>,private viewContainerRef: ViewContainerRef,private loginService:LoginService) { }
//   ngOnInit(){
//       let hasRole = this.loginService.hasRole(this.roles)
//       if(hasRole){
//         this.viewContainerRef.createEmbeddedView(this.templateRef)
//       }else{
//         this.viewContainerRef.clear()
//       }
//   }
// }
export class HasRoleDirective implements OnInit {
  @Input('appHasRole') roles: string[];
  @Input('appHasRoleFocus') focus = null;

  constructor(private templateRef:TemplateRef<any>,private viewContainerRef: ViewContainerRef,private loginService:LoginService,private authService:AuthService) { }
  ngOnInit(){
      let hasRole = this.loginService.hasRole(this.roles)
      let userRoleName=this.loginService.getUserRoleName().trim()
      let userInfo = this.authService.getUserInfo();
      if(hasRole && this.focus == null){
        this.viewContainerRef.createEmbeddedView(this.templateRef)
        return
      }

      if(hasRole && this.focus != null){

        if(userRoleName === USER_ROLES.USERS){

          if( this.focus.map(focus=> focus.toLowerCase()).includes(userInfo.userFocus?.toLowerCase())){
            this.viewContainerRef.createEmbeddedView(this.templateRef)
          }else{
            this.viewContainerRef.clear()
          }
        }
        else{
            this.viewContainerRef.createEmbeddedView(this.templateRef)
          }
        return 
      }

        this.viewContainerRef.clear()
      
  }
}