import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MenuController, Platform,IonSelect } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/authorization-details/auth-and-user-details.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LoginService } from './services/login-service/login.service';
import {NAVIGATIONS} from './components/main-tabs-menu/navigation-links';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  readonly _NAVIGATIONS = NAVIGATIONS
  activePage: any;
  currentTheme: any;
  isLogged: boolean;
  isLoggedIn$:Observable<boolean>
  userInfo$:Observable<any>
  userName: any;
  userInfo:any;
  userNameMobile: any;
  user
  languages=[{lang:"English",urlPrefix:"en"},{lang:"Chinese",urlPrefix:"zhcn"},{lang:"Portuguese",urlPrefix:"ptbr"},{lang:"French",urlPrefix:"frca"},{lang:"Spanish",urlPrefix:"esla"}]
  selectedLanguage= new FormControl('en')
  languageMap = this.languages.reduce((acc,item)=>{
    acc[item.urlPrefix] = item.lang
    return acc
  },{})
  currentLang =  'en'
  @ViewChild('selectLanguageRef',{static:false}) selectLanguageRef:IonSelect;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuCtrl: MenuController,
    private authService: AuthService,
    private loginService:LoginService,
    private router: Router,
    @Inject(DOCUMENT) private document: any,

  ) {
    
    this.initializeApp();
    localStorage.setItem('currentTheme', 'light')
    this.currentTheme = localStorage.getItem('currentTheme');
    document.body.setAttribute('color-theme', 'light')
  }
  // langCode = ['zhcn','ptbr','frca','esla','en']
  langCode = this.languages.map(item=>item.urlPrefix)
  ngOnInit() {
    
    // this.currentLang = window.localStorage.getItem('lang') || 'en'
    /*let pathName = window.location.pathname
   
    let langFromUrl= 'en'
    for(let l of this.langCode){
      if(pathName.startsWith(`/${l}/`)){
        langFromUrl = l
        break
      }
    }
    window.localStorage.setItem('lang',langFromUrl)*/
    this.currentLang = window.localStorage.getItem('lang') || 'en'
    this.selectedLanguage.patchValue(this.currentLang)
    this.loginService.isLoggedIn$.subscribe((isLoggedIn)=>{
        this.isLogged =!!isLoggedIn
     })
     this.loginService.userInfo$.subscribe((userInfo)=>{
       if(userInfo){
         this.userInfo = userInfo
          this.userName = userInfo.firstName + ' ' + userInfo.lastName;
       }
     })

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  isUserLoggedIn() {
    this.isLogged = this.authService.isLoggedIn();
  }


  showMenuIcon(page: any) {
    this.activePage = page;
  }

  toggleMenu() {
    this.menuCtrl.toggle('main');
  }
  closeSideNavigation(){
    this.menuCtrl.close('main');
  }

  toolbarSelect(value: string) {
    if(value == 'profile'){
      this.router.navigate([`/${this.currentLang}/profile`], {
        replaceUrl: true,
      });
    }
    if(value == 'logout'){
      let idp = this.authService.getUserInfo().idp
      localStorage.clear();
      let logoutUrl =  idp == environment.azureProvider ? environment.logoutAzureUrl: environment.logoutIAMUrl
      this.document.location.href = logoutUrl;
    }
    
  }
  openLanguage(event: MouseEvent){
    this.selectLanguageRef.interface = 'popover'
    this.selectLanguageRef.open(event)
  }

  
  _setLanguage(){
    let lang = this.selectedLanguage.value

    let searchParam = window.location.search
    let pathName = window.location.pathname
    let host = window.location.host
    let protocol = window.location.protocol

    let langRedirectUrl
    if(lang =='en'){
      langRedirectUrl =  `${environment.oauth2.redirectUri}${pathName}${searchParam}`
    }else{
      langRedirectUrl =  `${protocol}//${lang}-${host}${pathName}${searchParam}`

    }
    //console.log(langRedirectUrl)
    window.location.href = langRedirectUrl
  //  let [protocol,rest]=currentURL.split('://')
  //  let redirectURL = lang == 'en'?  `${protocol}://${rest}` :  `${protocol}://${lang}-${rest}`
  //  this.document.location.href = redirectURL;
  
  }

  setLanguage(){
    //'http://edge360-dev.cummins.com/ptbr/planning-process/dashboard'
    let lang = this.selectedLanguage.value
    let url = window.location.href
    let previouslySelectedLang = 'en'
    for (let _lang of this.langCode) {
      if(url.indexOf(`/${_lang}/`)>-1){
        //found
        previouslySelectedLang = _lang
        break
      }
    }
    let langRedirectUrl = url.replace(`/${previouslySelectedLang}/`,`/${lang}/`)
    window.localStorage.setItem('lang',lang)
console.log(langRedirectUrl)
    window.location.href = langRedirectUrl

  
  }
}
