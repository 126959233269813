import { Component} from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-bottom-tab-bar',
  templateUrl: './bottom-tab-bar.component.html',
  styleUrls: ['./bottom-tab-bar.component.scss'],
})
export class BottomTabBarComponent {

  menu: any = [
    {
      displayName: 'Dashboard',
      route: '/dashboard',
      icon: 'analytics'
    },
    {
      displayName: 'Competency Configuration',
      route: '/competency-requirements-configuration',
      icon: 'server'
    },
    {
      displayName: 'Master Data Configuration',
      route: '/master-data-configuration',
      icon: 'server'
    },
    {
      displayName: 'User Access Management',
      route: '/user-access-management',
      icon: 'person-add'
    }
  ]

  constructor(public myapp: AppComponent) { }

  getCurrentPageMethod(page: any) {
   
    if (page == 'Components') {
      this.myapp.showMenuIcon('components-screen');
    }
    else {
      this.myapp.showMenuIcon(page.toLowerCase());
    }
  }

}
