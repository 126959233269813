import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { catchError, map, retry} from "rxjs/operators";
import { MessageService } from "../shared/components/message/message.service";
import { GENERAL_CONST, STATUS_MAP } from "../constants/Constants";
import { AlertController } from "@ionic/angular";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  baseUrl = environment.apiHostUrl;
  api_fetch_master_data = this.baseUrl + "/fetch-master-data";
  api_fetch_master_data_config = this.baseUrl + "/fetch-master-data-config";
  api_fetch_assessments = this.baseUrl + "/assessment-list";
  api_fetch_dynamic_list=this.baseUrl + "/dynamic-list"
  api_fetch_master_dropdown_list=this.baseUrl + "/master-data-dropdowns";
  fetch_assessment = this.baseUrl + "/assessment-details";
  rrm_user_list = this.baseUrl + "/fetch_user_details"
  iam_user_list = this.baseUrl + "/fetch_user_details_iam"
  IMAGE_UPLOAD= this.baseUrl + "/upload-details";
  
  constructor(private http: HttpClient, private messagePopupService: MessageService,private alertController: AlertController,private storageService: StorageService) {}

  _old_getMasterDataDropdownList(): Observable<any> {
    return this.http.get<any>(this.api_fetch_master_data).pipe(
      map((res) => {
        res.segments.sort(function (a, b) {
          if (a.segment < b.segment) {
            return -1;
          }
          if (a.segment > b.segment) {
            return 1;
          }
          return 0;
        });
        res.region.sort(function (a, b) {
          if (a.region_name < b.region_name) {
            return -1;
          }
          if (a.region_name > b.region_name) {
            return 1;
          }
          return 0;
        });
        return res;
      })
    );
  }

 
  getMasterDataDropdownList(payload): Observable<any> {
    return this.http.post<any>(this.api_fetch_master_data,payload).pipe(
      map((res) => {
        if(res.segments){
          res.segments.sort(function (a, b) {
            if (a.segment < b.segment) {
              return -1;
            }
            if (a.segment > b.segment) {
              return 1;
            }
            return 0;
          });
        }
        if(res.region){
          res.region.sort(function (a, b) {
            if (a.region_name < b.region_name) {
              return -1;
            }
            if (a.region_name > b.region_name) {
              return 1;
            }
            return 0;
          });
        }
       
        return res;
      })
    );
  }
  //pairs function-capabilities group with function name
  getFunctionsCapabilitiies(): Observable<any> {
    //capabilities group according to function name
    let requestBody = {
      action: "capability",
      user: "",
      limit: 999999999,
      pageNumber: 1,
      sortKey: "created_date",
      sortType: "ASC",
      filter: {'capability':[],'function_name':[]},
    };

    return this.http
      .post<any>(this.api_fetch_master_data_config, requestBody)
      .pipe(
        map((res) => {
          let allCapabilities = res.routes.map((cap) => {
            return {
              function_name: cap.function_name,
              capability: cap.capability,
            };
          });
          //group capabilities according to function_name so that we can use capbilities option according to function_name selected
          return allCapabilities.reduce(
            (acc, item) => {
              let key = item["function_name"];
              acc[key] = acc[key] || [];
              acc[key].push(item.capability);
              return acc;
            },
            {}
          );

          
        })
      );
  }


  //dynamic list contains asseessmentInfo, Capabilities,UniqueFunctions and segments list for each function 
  getDynamicList(assessmentID):Observable<any>{
    return this.http.get<any>(this.api_fetch_dynamic_list +'/'+ assessmentID).pipe(
      map((res)=>{
        let {unique_function_list,function_segments,assessment_details,function_capability} = res
        let functionSegmentsLookup = {}
        let functionCapabilitiesLookup={}
        if(function_segments[0]){
        functionSegmentsLookup = function_segments[0].reduce((acc, item) => { 
            acc[item.function_name] = item.segments
            return acc;
          },{});
        }
        
      if(function_capability){
       functionCapabilitiesLookup = this.groupBy('function_name',function_capability)
      }
      return{
        ...res,
        assessment_details:assessment_details[0],
        unique_function_list:unique_function_list[0],
        function_segments:functionSegmentsLookup,
        function_capability:functionCapabilitiesLookup
      }
      })
    )
  }
  getMasterDataDropdown(masterDataNames:string[]):Observable<any>{
    return this.http.post(this.api_fetch_master_dropdown_list,{action:masterDataNames})
  }


  groupBy(key:string,array){

    if(!array) {
      return {}
    }else{
      return  array.reduce((acc, item) => {
        let property = item[key];
        acc[property] = acc[property] || [];
        acc[property].push(item);
         return acc;
      },{});
    }
  }

  
  getAllAssessmentDetails(id): Observable<any> {
    return this.http.get<any>(this.fetch_assessment + "/" + id);
  }
  getAssessmentDetails(id): Observable<any> {
    return this.http.get<any>(this.fetch_assessment + "/" + id);
  }
  getUploadImageDetails(data): Observable<any> {
    return this.http.post<any[]>(this.IMAGE_UPLOAD, data)
      .pipe(map((response: any) => { return response}));
  }
  uploadToS3(file: any,presignedUrl: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.put(presignedUrl, file, { headers: headers, reportProgress: true });

  }
  
  getRRMUserList(role): Observable<any>{
    return this.http.get<any>(this.rrm_user_list + "/"+ role);
  }
  getIAMUserList(regions:string[]): Observable<any>{
     return this.http.post<any>(this.iam_user_list,{region : regions || []})
        .pipe(
          retry(1),
          catchError((err:any)=>{
            this.messagePopupService.warning("Unable to retrieve IAM user details for this region")
            return of([])
           }) 
        
    ); 
 
  }
  getRegionUserName(user,focus,selectedRegion){
    let userName
    if(user.providerName === 'IAM' &&  user.roles.toLowerCase() === focus.toLowerCase()){
      userName = user.firstName + " " + user.lastName + " (" + user.userName + ")";
    }else if(user.wwid){
      let regionObj = user.attributeList.find((item) => {
           return item.hasOwnProperty("Region");
      });
      let focusObj = user.attributeList.find((item) => {
        return item.hasOwnProperty("Focus");
      });


      //superusers have multiple regions ... check if Region is assay
      //check if superuser is from  selected region
      if(regionObj?.Region && Array.isArray(regionObj?.Region) && regionObj.Region.includes(selectedRegion)){
          userName = user.firstName + " " + user.lastName + " (" + user.wwid + ")"; 
      }
        //for users
        if (regionObj?.Region == selectedRegion && focusObj?.Focus?.toLowerCase() === focus?.toLowerCase()) {
         userName = user.firstName + " " + user.lastName + " (" + user.wwid + ")";
        
        }
    }
    return userName
  }


  isRegionalUser(user,focus,selectedRegion){
    if(user.providerName === 'IAM' &&  user.roles.toLowerCase() === focus?.toLowerCase()){
      return true
    }else if(user.wwid && user.attributeList){
      let regionObj = user.attributeList.find((item) => {
           return item.hasOwnProperty("Region");
      });
      let focusObj = user.attributeList.find((item) => {
        return item.hasOwnProperty("Focus");
      });

      //superusers have multiple regions ... check if Region is assay
      //check if superuser is from  selected region
      if(regionObj?.Region && Array.isArray(regionObj?.Region) && regionObj.Region.includes(selectedRegion)){
         return true
      }
        //for users
        if (regionObj?.Region == selectedRegion && focusObj?.Focus?.toLowerCase() === focus?.toLowerCase()) {
        return true
        
        }
    }
    return false
  }
  generateUsersDropdown(userList,focus,region){
    let dropdown = []
    userList.forEach((_user) => {
    
        let isRegionalUser = this.isRegionalUser(_user, focus, region)
        if (isRegionalUser) {
          dropdown['full_name'] = `${_user['firstName']} ${_user['lastName']}`
          dropdown['id'] = _user['wwid']
          dropdown['db_user_name'] = `${_user['firstName']} ${_user['lastName']} (${_user['wwid']})`
          dropdown.push(_user)
        }
      
    })
   return dropdown
  }
  getStatusColor(status='unknown') {
    return STATUS_MAP[status?.toLowerCase()] ? STATUS_MAP[status?.toLowerCase()].className : 'chip-dark'
  }
  getLabel(status='unknown') {
    return STATUS_MAP[status?.toLowerCase()] ? STATUS_MAP[status?.toLowerCase()].label : status
  }

  async confirm(_header,_message?){
    const alert = await this.alertController.create({
      header:_header,
      message:_message ? _message:'',  
      backdropDismiss:true,
      buttons:[ {text:'Confirm',role:'Confirm'},{ text:'Cancel', role:'Cancel'}]
       })
     await alert.present()
     let result = await alert.onDidDismiss();
      if(result.role == 'Confirm'){
       return true
      }else{
        return false
      }
   }

getLastSelectedPageNum(key){
  let pageSizesMap = this.storageService.getObjectFromStorage(GENERAL_CONST.PAGE_SIZES_MAP)
  return (pageSizesMap && pageSizesMap[key]) || GENERAL_CONST.DEFAULT_PAGE_SIZE
  
 //return GENERAL_CONST.DEFAULT_PAGE_SIZE
}
setPageNum(key,value){
  let pageSizesMap = this.storageService.getObjectFromStorage(GENERAL_CONST.PAGE_SIZES_MAP)
       if(pageSizesMap){
         this.storageService.setObjectInStorage(GENERAL_CONST.PAGE_SIZES_MAP , {...pageSizesMap ,[key]:+value})
    }
    
}

trimData(keys,data){
 let  _data = {...data}
  keys.forEach(key=>{
    if(_data[key]){
      _data[key] = _data[key].trim()
    }
  })

  return _data
}

getLangugeSpecificURL(url){
  return url.replace(`${environment.oauth2.redirectUri}`,`${window.location.origin}`)
}
getPreviouslySelectedLang(){
  return this.storageService.getFromStorage('lang') || 'en'
}
}
