import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NavigationInfo } from '../../services/navigation-info/navigation-info';
import { USER_ROLES } from 'src/app/constants/Constants';
import {NAVIGATIONS} from './navigation-links'
@Component({
  selector: 'app-main-tabs-menu',
  templateUrl: './main-tabs-menu.component.html',
  styleUrls: ['./main-tabs-menu.component.scss'],
})
export class MainTabsMenuComponent {
  readonly USER_ROLES = USER_ROLES
  readonly NAVIGATIONS = NAVIGATIONS
  constructor(private route: Router, public myapp: AppComponent, private navInfo: NavigationInfo) { }

  tabsClicked(page: any) {
    this.myapp.showMenuIcon(page);
    if (page == 'components-screen') {
      this.navInfo.saveIndex(undefined);
    }
    else if (page == 'themes-screen') {
      this.route.navigate(['/themes-screen'], { replaceUrl: true });
    }
  }

}
