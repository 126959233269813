import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FooterComponent } from './components/footer/footer.component';
import { MainTabsMenuComponent } from './components/main-tabs-menu/main-tabs-menu.component';
import { BottomTabBarComponent } from './components/bottom-tab-bar/bottom-tab-bar.component';
import { RouterModule } from '@angular/router';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HasRoleDirective } from './shared/directives/has-role.directive';
import { AssessmentInfoComponent } from './shared/components/assessment-info/assessment-info.component';
import { CMIPaginatorComponent } from './shared/components/cmi-paginator/cmi-paginator.component';
import { MessageComponent } from './shared/components/message/message.component';
import { InfoPopOverComponent } from './shared/components/info-pop-over/info-pop-over.component';
import { CmiMultiselectDdComponent } from './shared/components/cmi-multiselect-dd/cmi-multiselect-dd.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchableMultiSelectModule } from './shared/searchable-multi-select/searchable-multi-select.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { HorizontalTabComponent } from './shared/components/horizontal-tab/horizontal-tab.component';

@NgModule({
    declarations: [MainTabsMenuComponent,
        BottomTabBarComponent,
        FooterComponent,
        HasRoleDirective,
        AssessmentInfoComponent,
        CMIPaginatorComponent,
        MessageComponent,
        InfoPopOverComponent,
        CmiMultiselectDdComponent,
        ClickOutsideDirective,
        HorizontalTabComponent
    ],
    entryComponents: [InfoPopOverComponent],
    providers: [],
    imports: [FormsModule, CommonModule, ReactiveFormsModule, IonicModule.forRoot(), HttpClientModule, NgxPaginationModule, RouterModule, 
        NgxDaterangepickerMd.forRoot(),SearchableMultiSelectModule,MatAutocompleteModule

    ],
    exports: [MainTabsMenuComponent,
        FooterComponent,
        BottomTabBarComponent,
        HasRoleDirective,
        AssessmentInfoComponent,
        InfoPopOverComponent,
        MessageComponent,
        CMIPaginatorComponent,
        CmiMultiselectDdComponent,
        ClickOutsideDirective,
        MatTooltipModule,
        NgxPaginationModule,
        SearchableMultiSelectModule,
        MatAutocompleteModule,
        HorizontalTabComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class SharedModule { }
