
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cmi-multiselect-dd',
  templateUrl: './cmi-multiselect-dd.component.html',
  styleUrls: ['./cmi-multiselect-dd.component.scss'],
})
export class CmiMultiselectDdComponent implements OnInit {
  
 
  multiSelectData=[]
  multiSelectDataCopy: any;
  selectedData: any = [];
  mixData: any;
  badgeCount: number;
  isDropDownVisible: boolean = false;
  @ViewChild('searchTerm') searchTerm:HTMLInputElement
  @Output() selectionChange = new EventEmitter<any>()
  @Output() onOutsideClicked = new EventEmitter<any>()
  @Input('translate') translate = false
  @Input() 
  set list(value){
   
     if(!value) this.multiSelectData=[]
     else{
      this.multiSelectData = value.map((val)=>{
        let existingOptionIndex = this.selectedData.findIndex(item=>item.option == val)
       
        return {
          option:val,
          isChecked:  existingOptionIndex > -1 ? true : false
        }
      })
      // remove already selected from multiSelectDataCopy
        this.multiSelectDataCopy = this.multiSelectData.filter(opt=> !opt.isChecked);
     
      this.mixData = [...this.multiSelectData];
      
     }
 }
   
  ngOnInit() {
    this.selectedData= [];  
    this.isDropDownVisible = false;
    this.badgeCount = this.selectedData.length;
  }

  multiSelectClicked() {
    // this.isDropDownVisible = this.isDropDownVisible == true ? false : true;
    this.isDropDownVisible = !this.isDropDownVisible;
  }

  multiSelectInputClicked() {
    this.isDropDownVisible = true;
  }

  /* search records */
  search(searchTerm: any) {
    if (searchTerm == "") {
      let newMultiSelectDataCopy = [...this.mixData]
      this.multiSelectDataCopy = newMultiSelectDataCopy.filter(obj => obj.isChecked != true)
    } else {
      this.multiSelectDataCopy = this.searchFunction(searchTerm);
    }
  }

  /* filters the search results */
  searchFunction(searchTerm: any) {
    
    return this.mixData.filter(obj => obj.isChecked != true && obj['option'].toLowerCase().includes(searchTerm.toLowerCase()))

  }

  onMultiSelected(index: any,event) {
    event.stopPropagation()
    let newMultiSelectDataCopy = [...this.multiSelectDataCopy]
    let newMixData = [...this.mixData];

   

    let j = newMixData.findIndex(item => item.option == newMultiSelectDataCopy[index].option)
    newMixData[j] = { ...newMixData[j], isChecked: true }
    this.mixData = newMixData;

    /*  Push select data to an array */
    let newSelectedData = newMultiSelectDataCopy.filter(obj => obj.option == this.multiSelectDataCopy[index].option);
    this.selectedData.push(newSelectedData[0]);

    /* Filter the selected data */
    let filteredData;
    filteredData = newMultiSelectDataCopy.filter(obj => obj.option != this.multiSelectDataCopy[index].option)
    this.multiSelectDataCopy = filteredData;

    this.badgeCount = this.selectedData.length;
    this.selectionChange.emit(this.selectedData.map(d=>d.option))



/*
    console.log('this.mixData',this.mixData)
    console.log('this.multiSelectDataCopy',this.multiSelectDataCopy)
    console.log('this.selectedData',this.selectedData)
   */
  }
  onSingleSelected(index: any,event) {
    event.stopPropagation()
    

    this.mixData = this.mixData.map((item,i) => {
    if(i==index){
      return {...item,isChecked : true}
    }else{
      return {...item, isChecked:false}
    }
   });

    /*  Push selected data to an array */
    let oldSelectedData = this.selectedData[0]
    let newSelectedData = this.multiSelectDataCopy[index];
    this.selectedData = [{...newSelectedData,isChecked:true}];

    /* Filter the selected data */
    if(oldSelectedData){
      this.multiSelectDataCopy.splice(index,1,{...oldSelectedData,isChecked:false})
    }else{
      this.multiSelectDataCopy.splice(index,1)
    }
  

    this.badgeCount = this.selectedData.length;
    this.selectionChange.emit(this.selectedData.map(d=>d.option))




    console.log('this.mixData',this.mixData)
    console.log('this.multiSelectDataCopy',this.multiSelectDataCopy)
    console.log('this.selectedData',this.selectedData)
    this.isDropDownVisible = false
   
  }
  deleteSelectData(index: any,event) {
    event.stopPropagation()
    let newSelectedData = [...this.selectedData];
    let newMixData = [...this.mixData];
    let j = newMixData.findIndex(item => item.option == newSelectedData[index].option);
    newMixData[j] = { ...newMixData[j], isChecked: false }
    this.mixData = newMixData;

    /* Filter the selected data */
    this.selectedData = newSelectedData.filter(obj => obj.option != newSelectedData[index].option);
    this.selectionChange.emit(this.selectedData.map(d=>d.option))

    this.multiSelectDataCopy = newMixData.filter(obj => obj.isChecked != true);


    this.badgeCount = this.selectedData.length;
  }

  badgeClicked() {
    this.selectedData = [];
    this.selectionChange.emit(this.selectedData)
    this.badgeCount = this.selectedData.length;

    let newMixData = [...this.mixData];
    newMixData.forEach(item => {
      let j = newMixData.indexOf(item);
      newMixData[j] = { ...newMixData[j], isChecked: false }
    })
    this.mixData = newMixData;

    this.multiSelectDataCopy = this.mixData;
  }
  

  reset(){
    this.searchTerm.value = ''
    this.multiSelectData = this.multiSelectData.map((item)=>{
      return {
        option:item.option,
        isChecked:false
      }
    })
      this.isDropDownVisible = false;
      this.multiSelectDataCopy = [...this.multiSelectData];
      this.selectedData=[]
      this.mixData = [...this.multiSelectData];
      this.badgeCount = 0;
      this.selectionChange.emit([])
   
  }

 clickOutside(event){
  this.isDropDownVisible = false
  
 }
 @HostListener('focusout', ['$event'])
 onFocusout(event) {
   // your logic goes here
  
 }

/* @HostListener('mouseout', ['$event'])
 onMouseout(event) {
   // your logic goes here
   console.log('on mouse out called'); // This will get printed on blur
 }*/

 _onMultiSelected(index: any,event) {
  event.stopPropagation()
  // this.multiSelectDataCopy = []
  // this.selectedData = []
  // this.mixData = this.mixData.map((item,i) => {
  //   if(i==index){
  //     console.log(item.option)
  //     this.selectedData = [{...item, isChecked:true}]
  //     return {...item,isChecked : true}
  //   }else{
  //     this.multiSelectDataCopy.push({...item, isChecked:false})
  //     return {...item, isChecked:false}
  //   }
  // });
    
   
   let oldSelectedData =  this.selectedData[0]
 
  /*  Push select data to an array */
   let newSelectedData = this.multiSelectDataCopy.find(obj => obj.option == this.multiSelectDataCopy[index].option);
   this.selectedData = [newSelectedData];

  /* Filter the selected data */
  
  this.multiSelectDataCopy = this.multiSelectDataCopy.filter(obj => obj.option != this.multiSelectDataCopy[index].option)
  
  if(oldSelectedData){
    this.multiSelectDataCopy.push(oldSelectedData)
  }

  this.badgeCount = this.selectedData.length;
  this.selectionChange.emit(this.selectedData.map(d=>d.option))

  this.isDropDownVisible = false
}


}
