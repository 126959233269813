import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-pop-over',
  templateUrl: './info-pop-over.component.html',
  styleUrls: ['./info-pop-over.component.scss'],
})
export class InfoPopOverComponent  {
  @Input() text:string=''
}
