import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot,Router } from '@angular/router';
import { AuthService } from 'src/app/services/authorization-details/auth-and-user-details.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {

  langCode = ['zhcn','ptbr','frca','esla','en']

  constructor(private authService: AuthService, private router: Router) { }

  //ensure that user is not allowed to visit login/registration page if already loggedIn
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let langFromUrl = state.url.split('/')[1]
    let currentLang = null
    currentLang = this.langCode.includes(langFromUrl) ? langFromUrl : null
    let userInfo = this.authService.getUserInfo()
    if (userInfo) {
        let _currentLang = currentLang || window.localStorage.getItem('lang') || 'en'
      
        this.router.navigateByUrl(`/${_currentLang}/dashboard`)
      return false;
    }else{

        return true

    }
  
  }
}

