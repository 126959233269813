import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class NavigationInfo {
    private menuAccordianIndex = new BehaviorSubject<any>("");
    private menuChildAccordianIndex = new BehaviorSubject<any>("");

    getMenuAccordianIndex: Observable<any>;
    getMenuChildAccordianIndex: Observable<any>;

    constructor() {
        this.getMenuAccordianIndex = this.menuAccordianIndex.asObservable();
        this.getMenuChildAccordianIndex = this.menuChildAccordianIndex.asObservable();

    }

    saveIndex(value: any) {
        this.menuAccordianIndex.next(value);
    }
    saveChildIndex(value: any) {
        this.menuChildAccordianIndex.next(value);
    }
}
